<template>
  <div  class="content my-2 mt-md-6 mb-16 px-0 px-sm-16  mx-sm-12" >

    <div v-if="!showBrandBanner && cate_banner!=''">
      <v-img class="categorybanner white--text align-center text-end" :aspect-ratio="$vuetify.breakpoint.mobile?610/422:1610/422"
        :src="domain + cate_banner"
      >
      <div v-if="catetitle!=''" class="catetitle black--text font-weight-bold text-center">
        <span class="" >{{catetitle}}</span>
      </div>
      </v-img>
    </div>

    <div v-if="showBrandBanner">
      <v-container >
      <v-row>
        <v-col cols="12" md="6" class="pa-0">

          <v-img  :src="domain + brandBannerInfo.brand_pic" ></v-img>
        </v-col>
        <v-col cols="12" md="6" class="d-flex flex-column justify-center grey lighten-4 px-0 pt-4 pb-8">

          <div class="text-h4 text-center font-weight-bold mb-2 mt-4">{{brandBannerInfo.brand_name}}</div>
          <div class="text-caption text-center  mb-2 mt-4 px-8 px-md-15 mx-6">{{brandBannerInfo.describe}}</div>
        </v-col>
      </v-row>
      </v-container>
    </div>

    <div class="mx-2 my-md-4">
      <v-row>
        <v-col class="d-flex " cols="4" md="2">
          <v-select
              :menu-props="{ contentClass: 'selectitem' }"
              item-color="secondary"
              :items="cate_list"
              v-model="checked_cate"
              item-text="category_name"
              item-value="category_id"
              label="品类"
              hide-details
              @change="searhgoods()"
            ></v-select>
        </v-col>
        <v-col class="d-flex " cols="4" md="2">
          <v-select
            item-color="secondary"
              :items="brand_list"
              v-model="checked_brand"
              item-text="brand_name"
              item-value="brand_id"
              label="品牌"
              hide-details
              @change="searhgoods()"
            ></v-select>
        </v-col>
        <v-col class="d-flex " cols="4" md="2">
          <v-select
              :items="colors"
              v-model="checked_color"
              label="颜色"
              item-color="secondary"
              hide-details
              @change="searhgoods()"
            ></v-select>
        </v-col>
        <v-col class="d-flex " cols="4" md="2">
          <v-select
              :items="prices"
              v-model="checked_price"
              label="价格"
              hide-details
              item-color="secondary"
              @change="searhgoods()"
            ></v-select>
        </v-col>
        <v-col class="d-flex " cols="4" md="2">
          <v-select
              :items="discounts"
              v-model="checked_discount"
              label="折扣"
              hide-details
              item-text="label"
              item-value="value"
              item-color="secondary"
              @change="searhgoods()"
            ></v-select>
        </v-col>
        <v-col class="d-flex " cols="4" md="2">
          <v-select
              :items="orderby"
              v-model="checked_orderby"
              label="排序"
              hide-details
               item-text="label"
              item-value="value"
              item-color="secondary"
              @change="searhgoods()"
            ></v-select>
        </v-col>
      </v-row>
    </div>

    <div class="pb-16" v-if="goods_list.length>0">
      <v-item-group >
      <v-row>
        <v-col v-for="goods in goods_list" class="pa-1" :key="goods.goods_id" cols="6" md="4">
          <Item :goods="goods" />
        </v-col>
      </v-row>
      </v-item-group>
    </div>
    <div class="pa-16 text-center" v-else>
      没有查询到商品~
    </div>



    <div  class="pa-5 text-center" v-if="goods_list.length<total">
      <v-btn  dark outlined width="30%" tile class="main-btn" @click="page++;goodslist()">查看更多</v-btn>
    </div>


  </div>
</template>

<script>

import goodsApi from '@/api/goods'
import { mapState } from 'vuex'
import Item from '@/components/Item';

export default {

  components: {
    Item
  },

  data: () => ({
    cate_banner : "",
    catetitle:"",
    total : 0,
    page_size:21,
    page:1,
    showBrandBanner : false,
    brandBannerInfo : {},
    cateid : 0,
    checked_cate :0,
    checked_discount : 0,
    checked_brand : 0,
    checked_color : [],
    checked_price : [],
    checked_orderby : "",
    home_carousel : [],
    cate_list: [],
    brand_list : [],
    goods_list : [],
    favorite : [],
    items: [{id:1,title:'上衣'}, {id:2,title:'裤子'},{id:3,title:'鞋袜'},{id:4,title:'皮鞋'},{id:5,title:'短板'}],
    colors: ["红色","橙色","黄色","绿色","青色","蓝色","紫色","黑色","白色"],
    prices: ["0-500","500-1000","1000-1500","1500-2000","2000-3000","3000-4000","4000-5000","5000+"],
    discounts: [{label:'大于20% OFF',value:1}, {label:'20%-50% OFF',value:2}, {label:'大于50% OFF',value:3}],
    orderby: [{label:'新品优先',value:'time_desc'}, {label:'价格：由高到低',value:'price_desc'}, {label:'价格：由低到高',value:'price_asc'}],


  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
      goods_banner: state => state.app.goods_banner,
    }),
  },
  created() {
    this.init()
    
  },
  watch: {
    $route : {
      
      handler() {
        if (this.$route.query.kw || this.$route.query.cate) {
          
          this.init()
        }
        if (this.$route.query.lastest  || this.$route.query.sale) {
          this.checked_cate = 0
          this.checked_brand = 0
          this.init()
        }
      }
      
    }
  },
  methods: {
    init() {
      let cateid = this.$route.query.cate
      let brandid = this.$route.query.brand
      
      
      
      cateid = parseInt(cateid)||0
      brandid = parseInt(brandid)||0
      
      this.goods_list = []
      
      goodsApi.filterdict(cateid).then((data) => {

        this.cate_list = data.data.child_list
        this.brand_list = data.data.brand_list
        /*if (data.data.cate_info.level==2 && cateid>0) {
          
          this.checked_cate = cateid
        }*/
        if (cateid>0) {
          this.checked_cate = cateid
        } else  if (brandid>0) {
          this.checked_brand = brandid
        } 
        this.goodslist()
        
      })
      
      
      
      
    },

    searhgoods() {
      this.page = 1
      this.goods_list = []
      this.goodslist()

    },  
    goodslist() {
      let kw = this.$route.query.kw
      let lastest = this.$route.query.lastest
      let issale = this.$route.query.sale

      lastest = parseInt(lastest)||0
      let params = {page_size:this.page_size,page_index:this.page}
      if (this.checked_brand>0) params.brand_id = [this.checked_brand]
      
      if (lastest>0) params.lastest = 1
      if (kw!=undefined) params.kw = kw

      if (this.checked_color) {
        params.colors = this.checked_color
      }
      if (this.checked_price) {
        params.price = this.checked_price
      }

      if (this.checked_discount>0) {
        params.discount = this.checked_discount
      }

      if (this.checked_orderby!="") {
        params.orderby = this.checked_orderby
      }
      
      if (this.checked_cate>0) {
        params.category_id = this.checked_cate
      } else if (this.cateid>0) {
        params.category_id = this.cateid
      }
      if (issale == "1") {
        params.sale = 1
      }
      
      goodsApi.goodslist(params).then((data) => {
        
        this.showgoodslist(data)
      })
    },
    showgoodslist(data){
      
      //this.goods_list = data.data.goods_list.data
      
      if (data.data.brand_info) {
        this.showBrandBanner = true
        this.brandBannerInfo = data.data.brand_info
        this.catetitle=""

        let shareimg = null
        if (this.brandBannerInfo.brand_logo){
          shareimg = this.domain + this.brandBannerInfo.brand_logo
        }
        this.$util.weixinShare(window.location.href, this.brandBannerInfo.brand_name, shareimg)

      }
      else if (data.data.current_category.category_id>0){
        this.catetitle = data.data.current_category.category_name
        if (data.data.current_category.category_banner!="") {
          this.cate_banner = data.data.current_category.category_banner

        } else {
          this.cate_banner = this.goods_banner
        }
        this.$util.weixinShare(window.location.href, this.catetitle)
      }
      else {
        this.showBrandBanner = false
        this.brandBannerInfo ={}
        this.catetitle=""
        this.cate_banner = this.goods_banner

        this.$util.weixinShare(window.location.href, 'VL.WANG时尚')
      }
      
      this.$util.setTitle()
      this.total = data.data.goods_list.total_count

      data.data.goods_list.data.forEach(item => {
        item.img = item.img_list[0].pic_cover_big
        if (item.img_list.length>1) {
          item.img2 = item.img_list[1].pic_cover_big
        }
        this.goods_list.push(item)
      })
      //console.log(this.goods_list)
    },

  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.goods-item {
  cursor: pointer;
}
.catetitle {
  font-size:35px;
  letter-spacing: 12px;
}

/deep/ .v-btn--absolute.v-btn--right {
  right:0px;
}
/deep/ .v-text-field .v-label {
  top: 2px;
  color: black !important;
}
</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .categorybanner .v-image__image--cover
      background-position : center

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .categorybanner .v-image__image--cover
      background-position : 17% !important

  .selectitem 
    border: 1px solid grey
  
</style>